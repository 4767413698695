import { Link } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../../components/layout/layout';

const Dechroming: React.FC = () => {
    return (
        <Layout
            title="Dechroming - WrapTown - profesjonalne oklejanie samochodów"
            desc="Chciałbyś zmienić kolor srebrnych listw w Twoim samochodzie? Zapraszamy do WrapTown, gdzie okleimy srebrne elementy czarną folią."
            url="https://wraptown.pl/dechroming"
            type="website"
        >
            <Helmet>
                <title>
                    Dechroming - WrapTown - profesjonalne oklejanie samochodów
                </title>
                <meta
                    name="description"
                    content="Chciałbyś zmienić kolor srebrnych listw w Twoim samochodzie? Zapraszamy do WrapTown, gdzie okleimy srebrne elementy czarną folią."
                />
            </Helmet>
            <main className="px-4 max-w-7xl mx-auto pt-20 pb-20 lg:px-6">
                <div className="mb-10">
                    <p>
                        <Link to="/">Home</Link> /{' '}
                        <Link to="/uslugi">Usługi</Link> / Dechroming
                    </p>
                </div>
                <div>
                    <h1 className="text-4xl  mb-6 font-extrabold text-black xl:text-5xl leading-tight xl:leading-snug">
                        Dechroming - WrapTown
                    </h1>
                    <p>...</p>
                </div>
            </main>
        </Layout>
    );
};

export default Dechroming;
